<template>
  <div class="container">
    <component :is="componentToRender" />
  </div>
</template>

<script>
import ProyekAll from '@/components/ProyekAll.vue';
import ProyekBy from '@/components/ProyekBy.vue';
export default {
  name: "Proyek",
  components : {
    ProyekAll,
    ProyekBy
  },
  computed: {
    componentToRender() {
      if (this.$route.query.jenis) {
        return 'ProyekBy';
      } else {
        // kondisi jika jenis tidak ada atau tidak sama dengan 1 atau 2
        return 'ProyekAll';

      }
    }
  },
};
</script>

<style>
</style>