<template>
    <div class="container">
    <div class="conten">
      <div class="row">
        <div class="col col-md-12">
          <router-link to="/solusi?by=HR1">
          <v-img
            src="../assets/HIGH RISE.jpg"
            height="100%"
            loading="../assets/load.gif"
          ></v-img>
        </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col col-md-12">
          <router-link to="/solusi?by=INF">
          <v-img
            src="../assets/INFRA.jpg"
            height="100%"
            loading="../assets/load.gif"
          ></v-img>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col col-md-12">
          <router-link to="/solusi?by=LW">
          <v-img src="../assets/LOW RISE.jpg" height="100%"></v-img>
          </router-link>
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style>
  .conten {
    height: auto;
  }
  </style>