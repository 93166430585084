<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <v-img v-bind:src="gbr" style="margin-top: -30px" />
      </div>
    </div>
    <div>
      <h5>
        <b class="text-center">{{ title }}</b>
      </h5>
    </div>
    <div class="row">
      <div class="col col-md-6"></div>
      <div class="col col-md-6">
        <b style="font-size: 27px" class="text-danger">Temukan Produk</b>
        <p>yang sesuai dengan kebutuhan anda.</p>
        <div v-for="produk in listproduk" :key="produk.m_produk_id">
            <CardProduct :product="produk"  :isSize="'50%'"/>
            <!-- <p>ps</p> -->
        </div>
      </div>
    </div>
    <h5><b class="text-danger text-center">Solusi Lainnya</b></h5>
    <div class="row" style="margin-bottom: 10px;">
      <div class="col col-md-6" v-for="solusi in listsolusi" :key="solusi.solusi">
        <router-link :to="'/solusi?by='+solusi.solusi">
          <v-img :src="pilihgbr2(solusi.solusi)" height="100%"></v-img>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CardProduct from "../components/CardProduct.vue";
import { axiosInstance } from "@/main";
import _ from 'lodash'

export default {
  name: "Solusi",
  components: {
    CardProduct,
  },
  data() {
    return {
      gbr: this.pilihgbr(),
      title: this.pilihJudul(),
      loading: false,
      objOne : null,
      loading : false,
      listData : [],
      listsolusi : [],
      listproduk: [],
    };
  },
  created(){
    this.loadData()
  },
  methods: {
    pilihJudul() {
      let kat = this.$route.query.by;
      if (kat == "HR1") {
        return `Solusi Mortindo untuk gedung bertingkat`;
      } else if (kat == "LW") {
        return `Solusi Mortindo untuk rumah`;
      } else if (kat == "INF") {
        // this.title = `Solusi Mortindo untuk infrastruktur`
        return `Solusi Mortindo untuk infrastruktur`;
      }
    },
    pilihgbr2(kat){
        if (kat == "HR1") {
            return require("../assets/HIGH RISE.jpg");
        } else if (kat == "LW") {
            return require("../assets/LOW RISE.jpg");
        } else if (kat == "INF") {
            return require("../assets/INFRA.jpg");
        }
    },
    pilihgbr() {
      let kat = this.$route.query.by;
      if (kat == "HR1") {
        this.title = `Solusi Mortindo untuk gedung bertingkat`;
        return require("../assets/HIGH RISE BUILDING.jpg");
      } else if (kat == "LW") {
        this.title = `Solusi Mortindo untuk rumah`;
        return require("../assets/LOW RISE BUILDING.jpg");
      } else if (kat == "INF") {
        this.title = `Solusi Mortindo untuk infrastruktur`;
        return require("../assets/infrastruktur.jpg");
      }
    },
    racik(){
        let by = this.$route.query.by
        let data = this.listData
        console.log('lah,,',data);
        let solusi = _.chain(data)
                .map(obj => ({ solusi: obj.solusi }))
                .value();
        solusi =  _.uniqBy(solusi, 'solusi');
        solusi = _.filter(solusi, obj => obj.solusi !== by);
        this.listsolusi = solusi
        let produk_ = _.filter(data, obj => obj.solusi === by);
        this.listproduk = produk_
        // console.log('sssss',this.listproduk);

    },
    loadData(){
        axiosInstance
        .get("getSolusi", {
          params: {
          },
        })
        .then((r) => {
            console.log(r);
          if (r.status == "200") {
            this.loading = false;
            if (r.data) {
              let obj = r.data;
              this.listData = obj 
              console.log(this.listData);
              this.racik()
            }
          }
        });
    }
  },
  watch:{
    $route (to, from){
        this.gbr = this.pilihgbr()
        this.title = this.pilihJudul()
        this.loadData()
    }
    
  }
};
</script>

<style>
</style>