<template>
  <div class="main">
    <v-img
      :src="getImageUrl(obj.gambar)"
      height="70%"
      width="70%"
      @click="gotoDetail(obj.nama_produk)"
      class="text-center"
      lazy-src="../assets/dumy.png"
      style="cursor: pointer;"
    ></v-img>
    <div class="row">
      <div class="col-4 text-right">
        <v-img
          src="../assets/logomerah.png"
          height="100%"
          width="100%"
          style="margin-left: 20px;"
          contain
        ></v-img>
      </div>
      <div class="col-8 text-center">
        <b>{{ obj.nama_produk }}</b>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center" v-if="obj.short_desc">
        <b>Pelapis Kedap Udara dan Air</b>
      </div>
      <div class="col-6 bg-danger text-center text-white rounded">
        <b>Tingkat TKDN 70%</b>
      </div>
      <div class="col-6 text-center">
        <div class="text-primary" style="cursor: pointer;" @click="getDownload(obj.gambar_sertifikat)">Lihat Sertifikat</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    props : ["obj"],
    data(){
        return{
            baseUrl: this.$root.$url,
        }
    },
    methods : {
        getImageUrl(name) {
        //   console.log(`${this.baseUrl}imgproduct/tkdn/${name}`);
          return `${this.baseUrl}imgproduct/tkdn/${name}`;
        },
        gotoDetail(name) {
            this.$router.push({ path: "/product/", query: { name } });
        },
        getDownload(v){
            // console.log(v);
            let url = `${this.baseUrl}imgproduct/sertifikat/${v}`
            window.open(url, '_blank');
        }
    }
};
</script>

<style>
    .main{
        /* background-color: azure; */
        border-radius: 5px;
    }
</style>