<template>
  <div class="container">
    <div class="row">
      <div class="col-4 tkdn" v-for="item in listProduk" :key="item.m_tkdn_id" >
        <CardTkdn :obj="item"/>
      </div>
    </div>
  </div>
</template>

<script>
import CardTkdn from '@/components/CardTkdn.vue';
import { axiosInstance } from "@/main";

export default {
  components : {
    CardTkdn
  },
  data(){
    return{
      listProduk : [],
      loading : true,
    }
  },
  methods : {
    load(){
      axiosInstance
        .get("getTKDN", {
          params: {},
        })
        .then((r) => {
          // console.log(r.data);
          if (r.status == "200") {
            // console.log(r.data);
            this.loading = false;
            if (r.data) {
              let obj = r.data;
              this.listProduk = obj;
            }
          }
        });
    }
  },
  created(){
    this.load()
  }
}
</script>

<style>
  .tkdn{
    padding:10px;
    border-radius: 10px;
  }
</style>