<template>
  <div class="container">
    <div class="row" style="padding : 10px">
        <!-- desktop -->
        <div class="col col-4 d-none d-md-block" v-for="obj in listberita" :key="obj.m_berita_id">
            <div style="max-height: 150px;">
                <b>{{ obj.judul }}</b>
            </div>
            <v-img
            :src="getImageUrl(obj.gambar1)"
            lazy-src="../assets/dumy.png"
            cover
            class="bg-grey-lighten-2"
            />
            <p>{{ obj.short_desc.substring(0,150) + '...' }}</p>
            <div class="float-right text-primary" style="margin-top:-10px;cursor:pointer" @click="goto_(obj.link)">
                Selengkapnya >>
            </div>
        </div>

        <!-- mobile -->
        <div class="col col-12 d-sm-block d-md-none" v-for="obj in listberita" :key="obj.m_berita_id">
            <div style="max-height: 150px;">
                <b>{{ obj.judul }}</b>
            </div>
            <v-img
            :src="getImageUrl(obj.gambar1)"
            lazy-src="../assets/dumy.png"
            cover
            class="bg-grey-lighten-2"
            />
            <p>{{ obj.short_desc.substring(0,150) + '...' }}</p>
            <div class="float-right text-primary" style="margin-top:-10px;cursor:pointer" @click="goto_(obj.link)">
                Selengkapnya >>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import _ from "lodash";

export default {
    name: "CardListPagination",
    data(){
        return{
            listberita : [],
            loading: false,
            baseUrl: this.$root.$url
        }
    },
    methods : {
        getImageUrl(name) {
            // console.log(`${this.baseUrl}imgproduct/${name}`);
            return `${this.baseUrl}berita/${name}`;
        },
        goto_(v){
            console.log(v);
            window.location.href = v;
        },
        loads(){
            // console.log('www');
            axiosInstance
            .get("getBerita", {
            params: {
                // kategori: kat,
                // key : this.keyCari
            },
            })
            .then((r) => {
            if (r.status == "200") {
                this.loading = false;
                if (r.data) {
                let obj = r.data;
                // console.log(obj);
                this.listberita = obj;
                }
            }
            });
        }   
    },
    created(){
        this.loads()
    }
}
</script>

<style>

</style>