<template>
  <div class="container" style="padding-top: 30px; padding-bottom: 30px">
    <!-- desktop -->
    <div class="row  d-none d-md-block">
      <div
        class="col"
        style="border-bottom: 10px;
          margin-top: -30px;
          background-color: #f15969;
          height: 80px;"
      >
        <div class="float-right" style="margin-top: 10px;">
          <div class="row">
            <div class="col">
              <input class="form-control" style="border-top-left-radius: 15px" v-model="keyCari"/>
            </div>
            <div class="col col-md-2">
              <button
                class="btn btn-primary float-right"
                style=" background-color: #e41e33"
                @click="cariData()"
              >
                Cari
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- desktop end -->
    <!-- mobile -->
    <div class="row d-sm-block d-md-none">
      <div
        class="col col-12"
        style="border-bottom: 10px;
          margin-top: -30px;
          background-color: #f15969;
          height: 80px;"
      >
            <div class="row">
              <div class="col col-10">
                <input class="form-control" v-model="keyCari"/>
              </div>
              <div class="col col-2">
                <button
                  class="btn btn-primary float-right"
                  style=" background-color: #e41e33"
                  @click="cariData()"
                >
                  Cari
                </button>
              </div>
            </div>
      </div>
    </div>
    <!-- mobile end -->
    <div v-if="items === null || items.length == 0">
      <div class="row">
        <div class="col text-center">
          <h4><b>NO DATA FOUND</b></h4>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col col-md-4" v-for="item in displayedItems" :key="item.id">
          <div class="row">
            <div class="col" style="margin: 5px; cursor: pointer">
              <CardProduct :product="item" @gotoDetail_emit="gotoDetail" />
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-container">
        <ul class="pagination">
          <li>
            <button :disabled="currentPage === 1" @click="prevPage">
              Sebelumnya
            </button>
          </li>
          <li
            v-for="pageNumber in pages"
            :key="pageNumber"
            :class="{ active: pageNumber === currentPage }"
          >
            <button @click="goToPage(pageNumber)">{{ pageNumber }}</button>
          </li>
          <li>
            <button :disabled="currentPage === numberOfPages" @click="nextPage">
              Berikutnya
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  
  <script>
import _ from "lodash";
import CardProduct from "../components/CardProduct.vue";
import { axiosInstance } from "@/main";

export default {
  name: "CardListPagination",
  components: {
    CardProduct,
  },
  data() {
    return {
      loading: false,
      itemsPerPage: 12,
      currentPage: 1,
      items: [],
      keyCari : null
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    displayedItems() {
      return _.chunk(this.items, this.itemsPerPage)[this.currentPage - 1];
    },
    pages() {
      let pagesArray = [];
      for (let i = 1; i <= this.numberOfPages; i++) {
        pagesArray.push(i);
      }
      return pagesArray;
    },
  },
  methods: {
    prevPage() {
      this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.numberOfPages) {
        this.currentPage++;
      }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    gotoDetail(name) {
      console.log(name);
      this.$router.push({ path: "/product/", query: { name } });
    },
    cariData(){
      // this.loadData()
      console.log(this.keyCari);
      let kat = this.$route.query.ketegory;
      axiosInstance
        .get("getProduk", {
          params: {
            kategori: kat,
            key : this.keyCari
          },
        })
        .then((r) => {
          // console.log(r.data);
          if (r.status == "200") {
            this.loading = false;
            if (r.data) {
              let obj = r.data;
              this.items = obj;
            }
          }
        });
    },
    loadData(){
      let kat = this.$route.query.ketegory;
      this.keyCari = this.$route.query.key ? this.$route.query.key : ""
      console.log(kat);
      axiosInstance
        .get("getProduk", {
          params: {
            kategori: kat,
            key : this.keyCari
          },
        })
        .then((r) => {
          if (r.status == "200") {
            this.loading = false;
            if (r.data) {
              let obj = r.data;
              this.items = obj;
            }
          }
        });
    }
  },
  created() {
    this.loadData()
  },
  watch :{
    $route (to, from){
        // this.show = false;
        // console.log(to);
        this.loadData();
    }
  }
};
</script>
  
  <style>
/* .card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
} */

.card {
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  text-align: center;
  cursor: pointer;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination {
  display: flex;
  /* justify-content: center; */
  list-style: none;
  padding: 0;
  margin: 0;
}
.pagination li.active button {
  background-color: #e41e33;
  color: #ffff;
}

.pagination li {
  margin: 0 5px;
}

.pagination button {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #666;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 10px;
}

.pagination button:hover {
  background-color: #f0f0f0;
}

.pagination button.active {
  background-color: #4caf50;
  border-color: #4caf50;
  color: #fff;
}

.pagination button:disabled {
  background-color: #ddd;
  border-color: #ddd;
  color: #666;
  cursor: default;
}
</style>
  