import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Products from '../views/Products.vue'
import ProductDetail from '../views/ProductDetail.vue'
import HubungiKami from '../views/HubungiKami.vue'
import Proyek from '../views/Proyek.vue'
import Solusi from '../views/Solusi.vue'
import Berita from '../views/Berita.vue'
import TKDN from '../views/TkdnView.vue'
import AllSolusiView from '../views/AllSolusiView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/home',
    name: 'beranda',
    component: HomeView
  },
  {
    path: '/tkdn',
    name: 'tkdn',
    component: TKDN
  },
  {
    path: '/berita',
    name: 'berita',
    component: Berita
  },
  {
    path: '/solusi',
    name: 'solusi',
    component: Solusi
  },
  {
    path: '/products',
    name: 'products',
    component: Products
  },
  {
    path: '/product',
    name: 'product',
    component: ProductDetail
  },
  {
    path: '/proyek',
    name: 'proyek',
    component: Proyek
  },
  {
    path: '/contact',
    name: 'contact',
    component: HubungiKami
  },
  {
    path: '/allsolusi',
    name: 'allsolusi',
    component: AllSolusiView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
