<template>
  <div class="container" style="padding-top: 30px;padding-bottom: 50px;">
    <div class="row" v-if="!productOne">
        <div class="col">
            <b>Nodata Found</b>
        </div>
    </div>
    <div class="row" v-else>
        <div class="col col-md-4">
            <v-img
            :src="getImageUrl(productOne.gambar)"
            lazy-src="../assets/dumy.png"
            cover
            class="bg-grey-lighten-2"
            />
            <div class="row" style="margin-top:10px">
                <div class="col">
                     <b>
                        Share to :
                     </b>
                </div>
            </div>
        </div>
        <div class="col col-md-8">
            <b><h1 class="text-danger">{{ productOne.nama_produk }}</h1></b>
            <b><h3>{{ productOne.short_desc }}</h3></b>
            <p>{{ productOne.description_1 }}</p>
            <button class="btn btn-success" style="margin-right: 10px;" @click="download(productOne.tds)">Data Teknis</button>
            <button class="btn btn-danger">Sertifikat TKDN</button>
        </div>
    </div>
    <div class="row">
        <div class="col col-md-12 text-center">
            <h3>PRODUK TERKAIT</h3>
        </div>
    </div>
    <div class="row row-horizon">
        <div class="col col-md-4" v-for="item in listProduct" :key="item.m_produk_id">
          <router-link :to='"/product/?name="+ item.nama_produk'>
            <CardSuggest :title="item.nama_produk" :description="item.description_1" :image="item.gambar" :short="item.short_desc"/>
          </router-link>
        </div>
    </div>
  </div>
</template>

<script>
import CardSuggest from "../components/CardSuggest.vue";
import { axiosInstance } from "@/main";

export default {
    components : {
        CardSuggest
    },
    data(){
        return{
            productOne : null,
            listProduct : null,
            baseUrl: this.$root.$url
        }
    },
    methods : {
        getImageUrl(name) {
            // console.log(`${this.baseUrl}imgproduct/${name}`);
            return `${this.baseUrl}imgproduct/${name}`;
        },
        download(v){
            // console.log(v);
            let url = this.baseUrl+"tds/"+v
            window.open(url, '_blank');
        },
        goto(){
            let name = this.$route.query.name;
            // console.log(name);
            axiosInstance
            .get("getProdukOne", {
            params: {
                nama : name
            },
            })
            .then((r) => {
            // console.log(r.data);
            if (r.status == "200") {
                this.loading = false;
                if (r.data) {
                let obj = r.data;
                // console.log(obj.data);
                this.productOne = obj.data[0]
                this.listProduct = obj.rekomendasi
                //   this.items = obj;
                }
            }
            });
        }
    },
    created(){
        this.goto()
    },
    watch: {
        $route (to, from){
            // this.show = false;
            // console.log(to);
            this.goto();
        }
    }
}
</script>

<style>
.card-list {
    display: flex;
    flex-wrap: nowrap; /* mengatur agar konten tidak wrap */
    -webkit-overflow-scrolling: touch; /* mengaktifkan smooth scrolling di iOS */
    scroll-behavior: smooth; /* membuat smooth scrolling di desktop */
    min-width: 100%; /* membuat kontainer selalu memiliki lebar yang sama */
}
</style>