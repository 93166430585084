<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <img
            :src=getImageUrl(image)
            width="110"
            height="150"
            alt="Card image"
            style="margin-top: -15px;margin-bottom: -15px;"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5 class="card-title"><b>{{ title }}</b></h5>
          <h5 class="card-title"><b>{{ short }}</b></h5>
          <!-- <button class="btn btn-danger">Lihat</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    title: String,
    description: String,
    short:String
  },
  data(){
    return{
      baseUrl: this.$root.$url
    }
  },
  methods:{
    getImageUrl(name) {
      console.log(`${this.baseUrl}imgproduct/${name}`);
      return `${this.baseUrl}imgproduct/${name}`;
    },
  }
};
</script>

<style>
.card {
  width: 300px;
  min-width: 200px;
  margin-right: 20px;
  height: auto;
  display: inline-block;
}
</style>