<template>
  <div style="background-color: white">
    <b-navbar class="container" toggleable="lg" type="dark" variant="#E41E33">
      <b-navbar-brand>
        <router-link to="/home">
          <!-- <v-img src="../assets/img_logo.png" height="80px" contain></v-img> -->
          <v-img src="../assets/logomerah.png" height="70px" contain></v-img>
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" class="bg-danger"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown
            class="text-white"
            text="PRODUK"
            right
            style="padding-right: 40px"
          >
            <b-dropdown-item
              ><router-link to="/products?ketegory=struktur"
                >Struktur</router-link
              ></b-dropdown-item
            >
            <b-dropdown-item
              ><router-link to="/products?ketegory=lantai"
                >Lantai</router-link
              ></b-dropdown-item
            >
            <b-dropdown-item
              ><router-link to="/products?ketegory=dinding"
                >Dinding</router-link
              ></b-dropdown-item
            >
            <b-dropdown-item
              ><router-link to="/products?ketegory=waterproofing"
                >Waterproofing</router-link
              ></b-dropdown-item
            >
            <b-dropdown-item
              ><router-link to="/products"
                >Semua Product</router-link
              ></b-dropdown-item
            >
          </b-nav-item-dropdown>
            <b-nav-item-dropdown
              text="PROYEK"
              right
              style="padding-right: 50px"
            >
              <b-dropdown-item
                ><router-link to="/proyek?jenis=HR1"
                  >High Rise Building</router-link
                ></b-dropdown-item
              >
              <b-dropdown-item
                ><router-link to="/proyek?jenis=LW"
                  >Low Rise Building</router-link
                ></b-dropdown-item
              >
              <b-dropdown-item
                ><router-link to="/proyek?jenis=INF"
                  >Infrastruktur</router-link
                ></b-dropdown-item
              >
            </b-nav-item-dropdown>
          <b-nav-item style="padding-right: 30px"><router-link style="color:black" to="/berita"
              >BERITA</router-link></b-nav-item>
          <b-nav-item style="padding-right: 30px"
            ><router-link style="color:black" to="/contact"
              >HUBUNGI KAMI</router-link
            ></b-nav-item
          >
          <b-nav-item
            ><router-link to="/about" style="color:black"
                >TENTANG KAMI</router-link>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {};
</script>
<style>
.navbar-dark .navbar-nav .nav-link {
  color: black !important;
  font-size: 15px;
}
.router-link{
  color: black !important;
}
</style>
