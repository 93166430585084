<template>
    <div class="rowItem" @click="gotoEmit(product.nama_produk)">
      <div class="col col-md-6">
        <v-img
            :src="getImageUrl(product.gambar)"
            sizes="100%"
            height="100%"
            lazy-src="../assets/dumy.png"
            contain
          ></v-img>
          <!-- <v-img
            :src="getImageUrl(product.gambar)"
            lazy-src="../assets/dumy.png"
            height="100%"
            width="100%"
            cover
            class="bg-grey-lighten-2"
            /> -->
      </div>
      <div class="col col-md-6">
        <b class="float-left text-danger text-left">{{ product.nomor ? product.nomor+". "+product.nama_produk :  product.nama_produk}}</b><br/>   
        <b class="float-left text-left">{{ product.short_desc }}</b><br/>   
        <p class="float-left text-danger text-left" style="font-size: 12px; height: 150px;">
          {{ product.description_1.substring(0,150) + '...' }}
        </p>
      </div>
    </div>
</template>

<script>
export default {
  props: ["product","isSize"],
  data(){
    return{
      baseUrl: this.$root.$url,
    }
  },
  methods:{
    gotoEmit(v){
        this.$emit('gotoDetail_emit', v)
    },
    getImageUrl(name) {
      // console.log(`${this.baseUrl}imgproduct/${name}`);
      return `${this.baseUrl}imgproduct/${name}`;
    },
  }
};
</script>

<style>
.rowItem {
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
</style>