<template>
  <v-app>
    <NavBar class="fixed-nav" />
    <v-main class="main-content">
      <router-view />
      <v-dialog v-model="showDialog" max-width="500">
        <template v-slot:activator="{ on }">
          <div
            class="floating-action-button"
            style="margin-bottom: 30px; margin-right: 30px"
          >
            <v-btn fab color="primary" dark v-on="on">
              <!-- <v-icon>mdi-plus</v-icon> -->
              <img src="./assets/wax.png" alt="" srcset="" />
            </v-btn>
          </div>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline text-danger">Chat Dialog</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="showDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <b-form-group
              id="input-group-1"
              label="Email address:"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="email"
                type="email"
                placeholder="Enter email"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-textarea
              id="textarea"
              v-model="isi"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <b-button variant="danger" style="margin-top: 10px;">Kirim</b-button>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
    <FooterWeb />
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import FooterWeb from "./components/FooterWeb.vue";

export default {
  name: "App",
  components: {
    NavBar,
    FooterWeb,
  },
  data() {
    return {
      showDialog: false,
      isi : "",
      email : ""
    };
  },
};
</script>

<style>
.floating-action-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
.fixed-nav {
  position: fixed;
  top: 0;
  height: 50;
  width: 100%;
  z-index: 999;
}
.main-content {
  margin-top: 95px; /* ubah nilai ini sesuai dengan tinggi NavBar */
}
</style>
