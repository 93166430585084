<template>
  <div >
    <div class="row" style="background-color:black; height: auto; margin-top: 0px;">
        <div class="col col-md-12">
            <div class="container">
              <div class="row">
                <div class="col col-md-3">
                  <v-img src="../assets/MORTINDO2.png"></v-img>
                  <v-img src="../assets/tpt.png"></v-img>
                </div>
                <div class="col col-md-5">
                  <div class="row">
                    <div class="col col-md-4">
                      <ul>
                        <li><b>Produk</b></li>
                        <li><hr class="text-white bg-white"></li>
                        <li><router-link class="text-white" to="/products?ketegory=struktur">Struktur</router-link></li>
                        <li><router-link class="text-white" to="/products?ketegory=lantai">Lantai</router-link></li>
                        <li><router-link class="text-white" to="/products?ketegory=dinding">Dinding</router-link></li>
                        <li><router-link class="text-white" to="/products?ketegory=waterproofing">Waterproofing</router-link></li>
                      </ul>
                    </div>
                    <div class="col col-md-4">
                      <ul>
                        <li><b>Produk</b></li>
                        <li><hr class="text-white bg-white"></li>
                        <li><router-link class="text-white" to="/proyek?jenis=HR1">High Rise Building</router-link></li>
                        <li><router-link class="text-white" to="/proyek?jenis=LW">Low Rise Building</router-link></li>
                        <li><router-link class="text-white" to="/proyek?jenis=INF">Infrastruktur</router-link></li>
                      </ul>
                    </div>
                    <div class="col col-md-4">
                      <ul>
                        <li><b>Solusi</b></li>
                        <li><hr class="text-white bg-white"></li>
                        <li><router-link class="text-white" to="/proyek?jenis=HR1">Bandara</router-link></li>
                        <li><router-link class="text-white" to="/proyek?jenis=LW">Fasilitas Transportasi</router-link></li>
                        <li><router-link class="text-white" to="/proyek?jenis=INF">Jalan</router-link></li>
                        <li><router-link class="text-white" to="/proyek?jenis=INF">Terowongan</router-link></li>
                        <li><router-link class="text-white" to="/proyek?jenis=INF">Gudang</router-link></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col col-md-4" style="display: flex;
                    justify-content: center;
                    align-items: center;">
                  <v-img class="text-center align-center" src="../assets/M Care.png" height="80px" contain></v-img>
                </div>
              </div>
            </div>
        </div>
    </div>
    <!-- <div class="container" style="margin-top:12px">
      <div class="row" style="background-color:#ffff;">
          <div class="col col-md-4">
              <v-img src="../assets/foot1.png" height="80px" contain></v-img>
          </div>
          <div class="col col-md-4">
              <v-img src="../assets/foot2.png" height="80px" contain></v-img>
          </div>
          <div class="col col-md-4">
              <v-img src="../assets/TRIPUTRA.png" height="80px" contain></v-img>
          </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {

}
</script>

<style>
  .b-list-group{
    border:none
  }
  ul {
    list-style-type: none;
  }
  hr{
    color:azure
  }
  li{
    color:aliceblue
  }
</style>