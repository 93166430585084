<template>
  <div class="container">
    <!-- row ke 1 -->
    <div class="row">
      <!-- desktop -->
      <div class="col-md-12 d-none d-md-block" style="height: 650px;margin-top:-20px">
        <!-- <router-link class="text-white" to="/tkdn"> -->
        <v-img lazy-src="../assets/dumy.png" src="../assets/headertop.png" height="100%">
          <div class="row">
            <div
              class="col col-md-4"
              style="padding-left: 140px; margin-top: 210px"
            >
              <b-form-input
                v-model="text"
                placeholder="Cari Produk atau Solusi"
              ></b-form-input>
            </div>
            <div
              class="col col-md-4"
              style="padding-left: 0px; margin-top: 210px"
            >
              <b-button
                pill
                variant="primary"
                @click="goTo()"
                style="
                  background-color: #e41e33;
                  width: 100px;
                  margin-left: -30px;
                "
                >Cari</b-button
              >
            </div>
          </div>

          <router-link to="./tkdn">
          <div class="text-white" style="position: absolute;
          bottom: 10px;
          right: 10px;
          cursor: pointer;">
            Lihat Semua Sertifikat >>
          </div>
          </router-link>
        </v-img>
      <!-- </router-link> -->
      </div>
      <!-- end desktop -->
      <!-- mobile -->
      <div class="col-md-12 d-sm-block d-md-none" style="height: 750px;margin-top:0px">
        <div class="row">
          <div class="col col-10">
            <b-form-input
              v-model="text"
              placeholder="Cari Produk atau Solusi"
            ></b-form-input>
          </div>
          <div class="col col-2">
            <b-button
              pill
              variant="primary"
              @click="goTo()"
              style="background-color: #e41e33;width: 70px; margin-left: -20px;">Cari</b-button
            >
          </div>
        </div>
        <v-img src="../assets/headermob.jpg" height="100%">
        </v-img>
      </div>
      <!-- end mobile -->
    </div>
    <!-- row ke 2 -->
    <div class="row">
      <div class="col col-md-12" style="margin-top: -24px">
        <router-link class="text-white" to="/proyek?jenis=LW">
           <v-img lazy-src="../assets/dumy.png" src="../assets/LOW RISE2.jpg" height="100%"></v-img>
          </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-12" style="margin-top: -24px">
        <router-link class="text-white" to="/proyek?jenis=HR1">
           <v-img lazy-src="../assets/dumy.png" src="../assets/HIGH RISE.jpg" height="100%"></v-img>
          </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-12" style="margin-top: -24px">
        <router-link class="text-white" to="/proyek?jenis=INF">
           <v-img lazy-src="../assets/dumy.png" src="../assets/INFRA2.jpg" height="100%"></v-img>
        </router-link>
      </div>
    </div>
    <div class="row" style="margin-top: -25px;">
      <div class="col col-md-6">
        <router-link class="text-white" to="/solusi">
           <v-img lazy-src="../assets/dumy.png" src="../assets/SOLUSI PRODUK.jpg" height="100%"></v-img>
        </router-link>
      </div>
      <div class="col col-md-6">
        <router-link class="text-white" to="/allsolusi">
           <v-img lazy-src="../assets/dumy.png" src="../assets/SOLUSI PROYEK.jpg" height="100%"></v-img>
        </router-link>
      </div>
    </div>
    <div class="row" style="margin-top: -25px;">
      <div class="col col-md-12">
        <router-link class="text-white" to="/berita">
          <v-img src="../assets/FOOTER.jpg" height="100%"></v-img>
        </router-link>
      </div>
    </div>
    <div class="row" style="padding-left: 15px;padding-right: 15px;margin-top: -20px;">
      <div class="col col-md-4"  style="background-color: black; cursor: pointer;">
        <v-img src="../assets/INSTAGRAM.png" height="100%" @click="gotos('https://www.instagram.com/p/Cptey5jv1KH/')"></v-img>
      </div>
      <div class="col col-md-4"  style="background-color: black; cursor: pointer;">
        <v-img src="../assets/Linked in.png" height="100%" @click="gotos('https://www.linkedin.com/posts/pt-anugerah-mitra-ananta_mortindo-bersertifikat-tkdn-activity-7031513775891456000-yHQY/?utm_source=share&utm_medium=member_desktop')"></v-img>
      </div>
      <div class="col col-md-4"  style="background-color: black; cursor: pointer;">
        <v-img src="../assets/YOUTUBE.png" height="100%" @click="gotos('https://www.youtube.com/watch?v=pSCg3yON-6E')"></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import HelloWorld from "../components/HelloWorld";

export default {
  name: "Home",

  components: {
    HelloWorld,
  },
  data() {
    return {
      text: "",
    };
  },
  methods: {
    goTo() {
      this.$router.push("/products?key="+this.text);
    },
    gotos(v){
      window.location.href = v;
    }
  },
};
</script>
