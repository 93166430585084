<template>
  <div class="container">
    <div class="row" style="margin-top: -45px">
      <div class="col" style="height: auto">
        <v-img v-bind:src="gbr" lazy-src="../assets/dumy.png" contain/>
      </div>
    </div>
    <div v-if="objekSelected === null || listObjek.length === 0">
      <div class="row">
        <div class="col text-center">
          <p><b class="text-center">NO DTA FOUND</b></p>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col col-md-12">
          <h2>
            <b class="text-danger">{{
              objekSelected ? objekSelected.nama_proyek : ""
            }}</b>
          </h2>
        </div>
      </div>
      <div class="row" style="height: 450px">
        <div class="col col-md-6">
          <v-img
            :src="getImageUrl(objekSelected ? objekSelected.gambar : '')"
            height="80%"
            lazy-src="../assets/dumy.png"
            width="90%"
            class="bg-grey-lighten-2"
          ></v-img>
        </div>
        <div class="col col-md-5">
          <h3>Product Terkait</h3>

          <v-carousel height="300">
            <v-carousel-item
              v-for="objek in objekSelected.products"
              :key="objek.m_produk_id"
            >
            <router-link class="text-white" :to="'/product/?name='+objek.nama_produk">
              <v-img lazy-src="../assets/dumy.png" :src="getImageUrlKecil(objek.gambar)" contain max-height="300"></v-img>
            </router-link>
            </v-carousel-item>
          </v-carousel>
        </div>
      </div>
      <div class="row" style="margin-top: -0px">
        <div class="col text-center">
          <h2 v-if="listObjek">Proyek Terkait</h2>
        </div>
      </div>
      <div class="row">
        <div
          class="col col-md-3"
          style="cursor: pointer"
          v-for="item in listObjek"
          :key="item.m_proyek_id"
          @click="pilihProyek(item)"
        >
          <v-img
            :src="getImageUrl(item.gambar)"
            lazy-src="../assets/dumy.png"
            height="100%"
            width="100%"
            style="display: inline-block"
          ></v-img>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { axiosInstance } from "@/main";
export default {
  data() {
    return {
      listObjek: null,
      gbr : this.pilihgbr(),
      objekSelected: null,
      loading: false,
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 200,
        class: "my-5",
      },
      baseUrl: this.$root.$url,
    };
  },
  methods: {
    getImageUrl(name) {
      return `${this.baseUrl}imgproduct/${name}`;
    },
    getImageUrlKecil(name) {
      // console.log(`${this.baseUrl}imgproduct/${name.replace("product","dtlproduk")}`);
      return `${this.baseUrl}imgproduct/${name.replace("product","dtlproduk")}`;
    },
    pilihgbr(){
      let kat = this.$route.query.jenis;
      console.log('.....');
      if(kat == "HR1"){
        return require("../assets/HIGH RISE BUILDING.jpg")
      }else if(kat == "LW"){
        return require("../assets/LOW RISE BUILDING.jpg")
      }else if(kat == "INF"){
        return require("../assets/infrastruktur.jpg")
      }
    },
    pilihProyek(proyek) {
      console.log(proyek);
      this.objekSelected = proyek;
    },
    loadData() {
      this.loading = true;
      this.objekSelected = null;
      this.listObjek = null;
      let kat = this.$route.query.jenis;
      this.gbr = this.pilihgbr()
      axiosInstance
        .get("proyek", {
          params: {
            kategori: kat,
          },
        })
        .then((r) => {
          if (r.status == "200") {
            console.log(r.data);
            this.loading = false;
            if (r.data) {
              let obj = r.data;
              this.listObjek = obj;
              this.objekSelected = obj[0];
              console.log(obj[0]);
            }
          }
        });
    },
  },
  watch:{
    $route (to, from){
        // this.show = false;
        // console.log(to);
        this.loadData();
    }
  },
  created() {
    this.loadData();
  },
};
</script>

<style>
.column {
  float: left;
  width: 33.33%;
  padding: 5px;
}

/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}
</style>