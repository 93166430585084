<template>
  <div class="container main">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="exampleInputEmail1">Nama</label>
          <input
            type="text"
            class="form-control"
            id="nama"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Email</label>
          <input
            type="text"
            class="form-control"
            id="email"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Tlp</label>
          <input
            type="text"
            class="form-control"
            id="tlp"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Profesi</label>
          <input
            type="text"
            class="form-control"
            id="profesi"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Kategori</label>
          <input
            type="text"
            class="form-control"
            id="kategori"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Pesan</label>
          <textarea
            class="form-control"
            id="pesan"
            maxlength="10"
            style="height: 100px;"
          ></textarea>
        </div>
        <button class="btn btn-danger">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
    .main{
        padding-bottom: 30px;
    }
</style>