import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/main.css'
import axios from 'axios'
import lodash from 'lodash'
import VueLazyload from 'vue-lazyload'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueLazyload)

Vue.config.productionTip = false

// const baseUrl = `http://localhost:81/`
const baseUrl = `https://api.mortindo.ama.id/`
Vue.prototype.$url = baseUrl;
const loadimage = require('./assets/load.gif')
Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: loadimage,
  attempt: 1
})

export const axiosInstance = axios.create({
    baseURL: baseUrl
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
